import { Component, inject } from '@angular/core';
import { AuthenticationService, FeatureFlagService } from 'app/_services';

@Component({ template: '' })
export abstract class RestrictionsComponent {
  authenticationService = inject(AuthenticationService);
  featureFlagService = inject(FeatureFlagService);

  get isReadonly(): boolean {
    return this.authenticationService.isReadonly;
  }
}
